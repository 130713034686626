
import { ref, onMounted, watch, Ref } from "vue";
import { defineComponent } from "vue";
import Api from "../../services/Api";
import useAlert from "../../composables/Alert";
import Button from "@/components/Button.vue"
import { Modal } from "bootstrap";
import ModalPlanilhaFiltro from "./components/ModalPlanilhaFiltro.vue";
import axios from "axios";
import { uploadMediaFromClient } from "@/services/AzureService";
import { BlobItem, BlobServiceClient } from "@azure/storage-blob";

export default defineComponent({
  name: "UploadPlanilhas",
  components: {
    Button,
    ModalPlanilhaFiltro,
  },

  setup() {

    const { showTimeAlert, showConfirmAlert} = useAlert();
    const buttonText = ref('Enviar planilha');
    const botaoDesabilitado = ref(false);
    const arquivo: Ref<any> = ref();
    const marca: Ref<string | null> = ref(null);
    const fileInput: Ref<any> = ref(null);
    const marcas: Ref<any> = ref([]);
    const historicoArquivos: Ref<any> = ref([]);
    const qtdArquivosSalvos: Ref<number> = ref(0)
    const file: Ref<any> = ref(null);
    const loadingUpload: Ref<boolean> = ref(false);  

    const rodandoFila = ref(false)
    const alteraRodandoFila = (valor: boolean) => rodandoFila.value = valor

    const caminhoDoArquivo = "AtriaLub/planilhas/atualizacao_filtros/";

    const arquivoSelecionado: Ref<any> = ref("")
    const modalPlanilhaFiltro: Ref<any> = ref()
    const openInput = () => fileInput.value.click();

    const uploadFile = async (event: any) => {
      const file = event.target.files[0]
      arquivo.value = file
    };

    async function getToken() {
      return localStorage.getItem('token_usuario');
    };

    function dropHandler(e) {

      stopDefault(e)

      if (e.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        [...e.dataTransfer.items].forEach((item) => {
          // If dropped items aren't files, reject them
          if (item.kind === "file") {
            file.value = item.getAsFile();
          }
        });
      } else {
        // Use DataTransfer interface to access the file(s)
        [...e.dataTransfer.files].forEach((fileParam) => {
          file.value = fileParam;
        });
      }
      arquivo.value = file.value
    }
    
    function stopDefault(e) {
      // Prevent default behavior (Prevent file from being opened)
      e.stopPropagation();
      e.preventDefault();
    }

    function formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    async function getMarcas() {
      const { data } = await Api.post("getMarcasFiltros")
      marcas.value = data
    }

    function limparArquivo() {
      arquivo.value = null
    }

    async function enviarPlanilha() {
      arquivo.value.idMarca = marca.value;
      const timeStamp = (new Date()).getTime();
      const caminhoStorage = 'planilhas/filtros/' + timeStamp + "-" + arquivo.value.name;
      const arquivoBlob = new Blob([arquivo.value]);
      const formData = new FormData();
      formData.append('nome', `${timeStamp}-${arquivo.value.name}`);
      formData.append('arquivo', arquivoBlob, arquivo.value.name);
      formData.append('idMarca', arquivo.value.idMarca);

      try{
        buttonText.value = 'Enviando...';
        botaoDesabilitado.value = true;

        const retorno = await axios.post("processaPlanilhaFiltro", formData, {
            headers: {
                'Allow-Access-Control-Origin': '*',
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${await getToken()}`,
            },
        });

        if(retorno.status === 200){
            const uploadBlobResponse = await uploadMediaFromClient(arquivo.value, caminhoStorage);
            if (uploadBlobResponse._response.status === 201) {

              showTimeAlert("Planilha enviada com sucesso");
              buttonText.value = 'Enviar planilha';
              botaoDesabilitado.value = false;
              pegarListaDePlanilhas();
              arquivo.value = null;
              marca.value = null;
            }
        }else{
          showTimeAlert("Erro ao enviar planilha ao bucket");
        }
      }catch(error: any){
        let mensagensError = "Erro ao fazer upload!";
        if(error.response){
            // O servidor respondeu com um status fora do intervalo 2xx
            if(error.response.status == 422){
              mensagensError = error.response.data.errors?.map((erro) => {
                return `Error  na linha ${erro.row} : ${erro.errors[0]} <br>`;
              });
            }else if(error.response.status == 500){
              mensagensError = `Erro no processamento da planilha<br>`;
            }
        }else if(error.request){
            // A requisição foi feita, mas não houve resposta
            mensagensError = `Erro na resposta do servidor<br>`;
        }else{
            // Algo deu errado ao configurar a requisição
            mensagensError = `Erro ao configurar a requisição<br>`;  
        }
        showConfirmAlert("", "error", " ", "OK", "center");
        buttonText.value = 'Enviar planilha';
        botaoDesabilitado.value = false;
        const divMessage = document.querySelector("#swal2-content");
        const modal = document.querySelector(".swal2-modal");
        modal?.classList.add("widthModal");
        //injeta os erros direto no html
        if (divMessage) divMessage.innerHTML = mensagensError.toString().replace(/,/g," ");
      }
    }


  async function pegarListaDePlanilhas() {
      const blobServiceClient = new BlobServiceClient(`https://${process.env.VUE_APP_AZURE_STORAGE_NAME}.blob.core.windows.net?${process.env.VUE_APP_AZURE_STORAGE_KEY}`);
      const containerClient = blobServiceClient.getContainerClient(process.env.VUE_APP_AZURE_STORAGE_CONTAINER!);

      const prefix = "planilhas/filtros/";
      const lista: any[] = [];
      const iterator = containerClient.listBlobsFlat({ prefix });

      const blobs: BlobItem[] = []; // ⬅️ Definindo o tipo do array para evitar erro

      for await (const blob of iterator) {
          blobs.push(blob);
      }

      // Ordenando por LastModified
      blobs.sort((a, b) => (b.properties.lastModified as any) - (a.properties.lastModified as any));

      // Buscando filas na API
      const { filas } = (await Api.get("getFilas")).data || [];

      blobs.forEach(blob => {
          const item = {
              nome: blob.name.replace(prefix, ''),
              status: 'concluído',
              erro: false,
              detalheErro: '',
              data: formatData(blob.properties.lastModified),
              pasta: blob.name,
          };

          filas.forEach(j => {
              if (item.nome === j.nome.replace('tabela_filtros_', '')) {
                  item.erro = j.erro;
                  item.status = j.status;
                  item.detalheErro = j.detalheErro;
              }
          });

          lista.push(item);
      });

      historicoArquivos.value = lista.slice(0, 10);
      qtdArquivosSalvos.value = blobs.length;
  }



    function formatData(data: Date) {

      const dia = data.getDate().toString()

      const diaF = (dia.length == 1) ? '0' + dia : dia

      const mes = (data.getMonth() + 1).toString() //+1 pois no getMonth Janeiro começa com zero.

      const mesF = (mes.length == 1) ? '0' + mes : mes

      const anoF = data.getFullYear();

      return diaF + "/" + mesF + "/" + anoF;
    }

    ///////////////////// MODAL PRODUTO LOG /////////////////////

    function abreModal(arquivo?: any) {
      arquivoSelecionado.value = arquivo || {}
      modalPlanilhaFiltro.value.show()
    }

    async function fechaModal() {
      arquivoSelecionado.value = ""
      modalPlanilhaFiltro.value.hide()
    }

    ///////////////////// MODAL PRODUTO LOG /////////////////////

    watch(() => rodandoFila.value, async () => { pegarListaDePlanilhas() }, { deep: true })

    onMounted(async() => {

      modalPlanilhaFiltro.value = new Modal(document.querySelector(`#ModalPlanilhaFiltro`));

      getMarcas()
      pegarListaDePlanilhas()
    })

    return {
      dropHandler,
      stopDefault,
      arquivo,
      limparArquivo,
      buttonText,
      botaoDesabilitado,
      enviarPlanilha,
      marcas,
      marca,
      historicoArquivos,
      formatBytes,
      openInput,
      uploadFile,
      fileInput,
      formatData,
      history,
      qtdArquivosSalvos,
      abreModal,
      fechaModal,
      arquivoSelecionado,
      rodandoFila,
      alteraRodandoFila,
      loadingUpload,
    }
  },
});
